import styles from './styles.module.css';
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
// import { Link } from 'react-router-dom';
import ShopSettingsService from '../../../services/ShopSettingsService';
import DataTable from '../../utils/DataTable';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
export default class ShopProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingIndicator: false,
      profile: '',
      unAppliedSubs: [],
    };
  }
  async componentDidMount() {
    this.loadShopProfile();
    this.loadUnappliedSubs();
  }
  loadShopProfile = async () => {
    this.setState({ loadingIndicator: true });
    const profile = await ShopSettingsService.loadShopProfile();
    this.setState({
      loadingIndicator: false,
      profile,
    });
  };
  loadUnappliedSubs = async () => {
    this.setState({ loadingIndicator: true });
    const plans = await ShopSettingsService.getSubscriptionPlans();
    const unAppliedPlans = await ShopSettingsService.getUnappliedSubscription();
    const unActivePlans = unAppliedPlans.map((plan) => ({
      subDetails: plans.find((pln) => pln.id === plan.subscription),
      ...plan,
    }));
    this.setState({
      unAppliedSubs: unActivePlans,
      loadingIndicator: false,
    });
  };
  handleActivationClick = (data) => {
    this.setState({
      subData: data,
    });
  };
  handleActivationTabClose = () => {
    this.setState({
      subData: '',
    });
  };
  handlePlanActivation = async (data) => {
    this.setState({ loadingIndicator: true });
    const reqData = {
      payment_id: data.id,
    };
    await ShopSettingsService.activatePlan(reqData);
    this.loadUnappliedSubs();
    this.setState({ loadingIndicator: false });
  };
  render() {
    const profile = this.state.profile || {};
    const subscription = profile.subscription || {};
    const headerData = [
      {
        id: 'subDetails.name',
        label: 'Subscription Name',
        type: 'text',
      },
      {
        id: 'subDetails.days',
        label: 'Subscription Period',
        type: 'callback',
        viewRender: (item) => {
          return `${item.subDetails.days}days`;
        },
      },
      {
        id: 'amount',
        type: 'floatAmount',
        label: 'Subscription Rate',
      },
      {
        id: 'activate',
        type: 'button',
        label: 'Activate',
        title: 'Activate',
        clickHandler: this.handleActivationClick,
      },
    ];
    return (
      <>
        <div className={styles.contentLayout}>
          <div className={styles.settingsGrid}>
            <ConfirmPopup
              data={this.state.subData}
              handleClose={this.handleActivationTabClose}
              handleOkay={this.handlePlanActivation}
              alertTitle={'Confirm Activation'}
              successBtnName={'Activate'}
              alertContent={'Do you want to activate the subscription?'}
            />
            <Grid container spacing={1}>
              <div style={{ marginBottom: 15 }}>
                <span className={styles.pageHead}>
                  Shop details of {profile.shop_name}
                </span>
              </div>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Shop Name</span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.shop_name}</span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Mobile No</span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{profile.mobile_no}</span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Subscription Type
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>{subscription.name}</span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Subscription Peroid
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.days} Days
                  </span>
                </div>
              </Grid>
              {profile.image_support_validity && (
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Image Support Validity
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <span className={styles.fieldTitle}>
                      {profile.image_support_validity}
                    </span>
                  </div>
                </Grid>
              )}
              {profile.kot_validity && (
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>KOT Validity</span>
                  </div>
                  <div className={styles.fieldInput}>
                    <span className={styles.fieldTitle}>
                      {profile.kot_validity}
                    </span>
                  </div>
                </Grid>
              )}
              {profile.kot_lite_validity && (
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>KOT lite Validity</span>
                  </div>
                  <div className={styles.fieldInput}>
                    <span className={styles.fieldTitle}>
                      {profile.kot_lite_validity}
                    </span>
                  </div>
                </Grid>
              )}
              {profile.expense_validity && (
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Additional Expense Validity
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <span className={styles.fieldTitle}>
                      {profile.expense_validity}
                    </span>
                  </div>
                </Grid>
              )}
              {profile.smart_scale_validity && (
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                      Smart Scale Validity
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <span className={styles.fieldTitle}>
                      {profile.smart_scale_validity}
                    </span>
                  </div>
                </Grid>
              )}
              {profile.customer_support_validity && (
                <Grid item xs={6} sm={12} className={styles.BillHeader}>
                  <div className={styles.fieldHead}>
                    <span className={styles.fieldTitleSmall}>
                    Customer Support Validity
                    </span>
                  </div>
                  <div className={styles.fieldInput}>
                    <span className={styles.fieldTitle}>
                      {profile.customer_support_validity}
                    </span>
                  </div>
                </Grid>
              )}


              {/* <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Subscription Rate
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    ₹ {subscription.rate}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Maximum Products
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.max_products === -1
                      ? 'Unlimited Products'
                      : `${subscription.max_products} Products`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Maximum Machines
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.max_machines === -1
                      ? 'Upto 5 Machines'
                      : `${subscription.max_machines} Machine`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Product Sync</span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.auto_sync_product_save === true
                      ? 'Automatic Product Sync to Machine '
                      : ` Manual Product Sync to Machine`}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>Sales Sync</span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {subscription.sales_sync_interval === -1
                      ? ' Automatic Sales Updates'
                      : ` Sales Update in ${subscription.sales_sync_interval} Hours`}
                  </span>
                </div>
              </Grid> */}
              <Grid item xs={6} sm={12} className={styles.BillHeader}>
                <div className={styles.fieldHead}>
                  <span className={styles.fieldTitleSmall}>
                    Subscription Validity
                  </span>
                </div>
                <div className={styles.fieldInput}>
                  <span className={styles.fieldTitle}>
                    {profile.subscription_end_date}
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* <div className={styles.Bill} style={{ placeSelf: 'flex-end' }}>
            <Grid item xs={6} sm={12} className={styles.BillHeader}>
              <div className={styles.fieldInput}>
                <span className={styles.fieldTitleSmall}>
                  Subscription Plans
                </span>
              </div>
              <div className={styles.fieldInput}>
                <Link
                  to="/subscription-plans"
                  style={{ textDecorationColor: 'transparent' }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={this.saveBtnPressed}
                    disabled={this.state.loadingIndicator}
                  >
                    purchase Plan
                  </Button>
                </Link>
              </div>
            </Grid>
          </div> */}
        </div>
        <div style={{ marginTop: 20 }}>
          {this.state.unAppliedSubs[0] && (
            <>
              <div>
                <span className={styles.subsTitle}>
                  Unapplied Subscriptions
                </span>
              </div>
              <DataTable
                columns={headerData}
                rows={this.state.unAppliedSubs}
                rowKey="id"
              />
            </>
          )}
        </div>
      </>
    );
  }
}
