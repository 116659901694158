import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Tooltip,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ItemService from '../../services/ItemService';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import RestrictionInfo from '../utils/Alert/RestrictionInfo';
import { ShopContext } from '../../Context/ShopContext';
import { SUBSCRIPTION_TYPE } from '../../const';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { DialogActions, Grid } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';
import DeleteIcon from '@mui/icons-material/Delete';
import { ITEM_TYPES } from '../../const';

export default function AddItemDialog(props) {
  const { items, categories, onSuccess, handleClose, itemCount } = props;

  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [plu, setPlu] = useState('');
  const [name, setName] = useState('');
  const [unit, setUnit] = useState('');
  const [price, setPrice] = useState('');
  const [cost, setCost] = useState('');
  const [leastPrice, setLeastPrice] = useState('');
  const [mrp, setMrp] = useState('');
  const [cess, setCess] = useState('');
  const [stock, setStock] = useState('');
  const [ean, setEan] = useState('');
  const [tax, setTax] = useState('');
  const [category, setCategory] = useState('');
  const [extenalId, setExtenalId] = useState('');
  const [error, setError] = useState(true);
  const [sync, setSync] = useState(false);
  const [available, setAvailable] = useState(false);
  const [order, setOrder] = useState(null);
  const [type, setType] = useState(ITEM_TYPES.INVENTORY);
  const [details, setDetails] = useState({
    sub_items: [],
  });
  const [searchItems, setSearchItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSearchList, setOpenSearchList] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [subItem, setSubItem] = useState({});

  const subscriptionType = shop?.subscription?.type;

  const fetchItems = useCallback(async () => {
    setLoading(true);

    if (searchText.trim()) {
      try {
        const response = await ItemService.getItemsResponse({ search: searchText });
        setSearchItems(response.data || []);
      } catch (error) {
        postAlertMessage({ text: error.message, type: 'failed' });
      }
    }

    setLoading(false);
  }, [searchText, postAlertMessage]);

  const removeSubItem = (subItem) => {
    const newSubItems = details.sub_items.filter((item) => item.item_id !== subItem.item_id);

    const updatedDetails = {
      ...details,
      sub_items: newSubItems,
    };

    setDetails(updatedDetails);
  };

  const addSubItem = () => {
    setDetails({ ...details, sub_items: [...details.sub_items, subItem] });
    setSubItem({});
    setSearchText('');
  };

  const handleAddItem = async () => {
    const data = {
      category: category,
      plu: Number(plu) <= 0 ? String(itemCount + 1) : plu,
      name,
      unit,
      price,
      cess: cess ? cess : 0,
      mrp: mrp ? mrp : 0,
      cost: cost ? cost : 0,
      stock: stock ? stock : 0,
      ean: ean ? ean : 'nil',
      tax: tax ? tax : 0,
      is_available: available ? true : undefined,
      sync_enabled: sync ? true : undefined,
      external_item_id: extenalId ?? null,
      order,
      least_price: leastPrice ? leastPrice : 0,
      details,
      type,
    };
    try {
      await ItemService.addItem(data);
      postAlertMessage({
        text: 'Item created successfully',
        type: 'success',
      });
      onSuccess();
      handleClose();
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
  };
  const checkPluIsAvailable = (value) => {
    setPlu(value);
    setError(!items.find((_item) => _item.plu === Number(value)));
  };
  const handleSuggestPlu = async () => {
    try {
      const resp = await ItemService.getHighestPlu(); 
      setPlu(String(resp?.highest_plu + 1));
    } catch (error) {
      setPlu(String(itemCount + 1));
    }
  };

  useEffect(() => {
    if (searchText && openSearchList) {
      const debounce = setTimeout(() => {
        fetchItems();
      }, 700);

      return () => clearTimeout(debounce);
    } else {
      setSearchItems([]);
    }
  }, [searchText, openSearchList, fetchItems]);

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create Item</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.PREMIUM && (
            <RestrictionInfo
              title={'Input restrictions '}
              content={'To get all input options upgrade subscription to premium'}
            />
          )}
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Item PLU</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Item PLU (Optional)"
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                error={!error}
                helperText={!error ? 'This PLU is already taken' : ''}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="To suggest a plu">
                        <IconButton onClick={handleSuggestPlu}>
                          <AutorenewIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  checkPluIsAvailable(e.target.value);
                }}
                value={plu}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}> Item Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Item Name"
                variant="outlined"
                size="small"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC ? (
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Item category</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  select
                  name="Unit"
                  size="small"
                  variant="outlined"
                  color="primary"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  fullWidth
                >
                  {categories.map((category) => (
                    <MenuItem value={category.id}>{category.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          ) : null}
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Unit</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                select
                name="Unit"
                size="small"
                variant="outlined"
                color="primary"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                fullWidth
              >
                <MenuItem value={'Kg'}>Kg</MenuItem>
                <MenuItem value={'Pcs'}>Pcs</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Price</h3>
            </Grid>

            <Grid
              item
              xs={subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC ? 8 : 3}
              sm={subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.BASIC ? 8 : 3}
            >
              <TextField
                label="Price"
                variant="outlined"
                size="small"
                type="number"
                className={styles.numberInput}
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
              />
            </Grid>
            {subscriptionType && subscriptionType !== SUBSCRIPTION_TYPE.BASIC && (
              <>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Cost</h3>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <TextField
                    label="Cost"
                    variant="outlined"
                    size="small"
                    type="number"
                    className={styles.numberInput}
                    name="cost"
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>MRP</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="MRP"
                variant="outlined"
                size="small"
                fullWidth
                name="mrp"
                value={mrp}
                onChange={(e) => setMrp(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>EAN</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Product Barcode"
                variant="outlined"
                size="small"
                fullWidth
                name="ean"
                value={ean}
                onChange={(e) => setEan(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} display={'flex'} justifyContent="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Tax</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Tax %"
                variant="outlined"
                size="small"
                type="number"
                className={styles.numberInput}
                name="tax"
                value={tax}
                onChange={(e) => setTax(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container pt={1} className={styles.subContainer}>
            <Grid item container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={4}
                sm={4}
                display={'flex'}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Item Type</h3>
              </Grid>
              <Grid item xs={8} sm={8} display={'flex'} justifyContent="flex-end">
                <TextField
                  select
                  name="type"
                  size="small"
                  variant="outlined"
                  color="primary"
                  value={type}
                  onChange={(e) => {
                    let type = e.target.value;
                    setType(e.target.value);
                    if (type === ITEM_TYPES.INVENTORY) {
                      setDetails({ ...details, sub_items: [] });
                    } else {
                      setStock(0);
                    }
                  }}
                  fullWidth
                >
                  <MenuItem value={ITEM_TYPES.INVENTORY}>Inventory</MenuItem>
                  <MenuItem value={ITEM_TYPES.COMBO}>Combo</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            {type !== ITEM_TYPES.COMBO && (
              <Grid container className={styles.inputRow} pt={2} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Stock</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Stock"
                    variant="outlined"
                    size="small"
                    type="number"
                    className={styles.numberInput}
                    name="stock"
                    value={stock}
                    onChange={(e) => setStock(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
            {type === ITEM_TYPES.COMBO && (
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid item container xs={12} pt={2} columnSpacing={1.5}>
                  <Grid item xs={7} display={'flex'} justifyContent={'center'}>
                    <h3 className={styles.inputSubLabel}>Sub items</h3>
                  </Grid>
                  <Grid item xs={3} display={'flex'} justifyContent={'center'}>
                    <h3 className={styles.inputSubLabel}>Fraction</h3>
                  </Grid>
                </Grid>
                <Grid item container xs={12} pt={1} pb={2} rowSpacing={1} columnSpacing={2}>
                  <>
                    <Grid item xs={7}>
                      <Autocomplete
                        fullWidth
                        size="small"
                        open={openSearchList}
                        onOpen={() => {
                          setOpenSearchList(true);
                        }}
                        onClose={() => {
                          setOpenSearchList(false);
                          setSearchItems([]);
                        }}
                        isOptionEqualToValue={(searchItem, value) => searchItem.name === value.name}
                        getOptionLabel={(searchItem) => searchItem.name}
                        options={searchItems}
                        loading={loading}
                        onInputChange={(event, newInputValue) => {
                          setSearchText(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSubItem({ ...subItem, item_id: newValue.id, name: newValue.name });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            size="small"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        variant="outlined"
                        size="small"
                        type="number"
                        className={styles.numberInput}
                        name="fraction"
                        value={subItem?.fraction || ''}
                        onChange={(e) => setSubItem({ ...subItem, fraction: Number(e.target.value) })}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        disabled={!subItem.fraction || subItem?.fraction === '' || !subItem.item_id}
                        onClick={addSubItem}
                      >
                        <LibraryAddIcon />
                      </Button>
                    </Grid>
                    {(details.sub_items || []).map((subItem) => {
                      return (
                        <>
                          <Grid item xs={7} className={styles.subLabel}>
                            {subItem?.name}
                          </Grid>
                          <Grid item xs={3} className={styles.subLabel}>
                            {subItem?.fraction || 0}
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                removeSubItem(subItem);
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Grid>
                        </>
                      );
                    })}
                  </>
                </Grid>
              </Grid>
            )}
          </Grid>
          {subscriptionType && subscriptionType === SUBSCRIPTION_TYPE.PREMIUM ? (
            <>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Additional Charge</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Additional Charge %"
                    variant="outlined"
                    size="small"
                    type="number"
                    className={styles.numberInput}
                    name="tax"
                    value={cess}
                    onChange={(e) => setCess(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>External Item ID</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="External Item ID"
                    variant="outlined"
                    size="small"
                    type="text"
                    className={styles.numberInput}
                    name="tax"
                    value={extenalId}
                    onChange={(e) => setExtenalId(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Order</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Order"
                    variant="outlined"
                    size="small"
                    type="number"
                    className={styles.numberInput}
                    name="order"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid
                  item
                  xs={4}
                  sm={4}
                  display={'flex'}
                  justifyContent="flex-end"
                  className={styles.inputLabelContainer}
                >
                  <h3 className={styles.inputLabel}>Least Price</h3>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <TextField
                    label="Least Price (Optional)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    name="leastPrice"
                    value={leastPrice}
                    onChange={(e) => setLeastPrice(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.inputRow} alignItems="center">
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" checked={sync} onChange={() => setSync(!sync)} />}
                    label="Export to scale"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" checked={available} onChange={() => setAvailable(!available)} />}
                    label="Available for sale"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
              CLOSE
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={name === '' || price === '' || unit === '' || category === '' || !error}
              onClick={handleAddItem}
            >
              Create Item
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
